html , body {
	padding: 0;
	margin: 0;
	line-height: 1.8em;
}

body, input, textarea {
	font-family: 'Fira Sans', sans-serif;
	color: $primary;
	-webkit-font-smoothing: antialiased;
}

h1 {
	font-size: 1.8em;
	margin: 0;
	padding: 0;
	line-height: 1.3em;
}

h2 {
	font-size: 1.4em;
}

time {
	color: $light-grey;
}

header {
	text-align: center;
	padding: 80px 0 60px 0;
}

img {
	max-width: 100%;
}

ol li + li {
	margin: 20px 0 0 0;
}

footer {
	color: $light-grey;
	text-align: center;
	padding: 20px 0;
	font-size: .8em;
}

hr {
	 border: 0;
	 border-top: 1px solid #eee;
}

a {
	text-decoration: none;
	color: $accent;
}
