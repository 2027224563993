nav {
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: center;
	background: rgba(255, 255, 255, .9);
	z-index: 999;

	a {
		display: inline-block;
		text-decoration: none;
		padding: 20px 15px;
		color: $primary;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: .9em;

		&.active {
			color: $accent;
		}

		&:hover {
			color: $secondary;
		}
	}
}
