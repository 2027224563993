// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 900px)";

// Colors
$primary: #0b2a2f;
$secondary: #7e2a0d;
$tertiary: #2b4227;
$accent: #a39328;
$light-grey: #cdc9cf;